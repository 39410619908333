<template>
	<div class='enterprise'>
		<div class="">
			<div>
				<div class="count_info">
					<div class="search_form">
						<a-input allow-clear v-model:value="where.keyword" placeholder="搜索岗位名称" @change="handleChange">
							<template #prefix>
								<a-icon type="search" style="color: #999999" />
							</template>
						</a-input>
					</div>
					<div style="flex:1;">
						<div style="text-align: right;width: 100%;">
							<a-button class='pointerCancel' :disabled='visibledrew' @click='visible=true'>
								添加职务
							</a-button>
							<a-dropdown :disabled='visibledrew' :trigger="['click']">
								<template #overlay>
									<a-menu>
										<a-menu-item key="1" @click='go_sort'>
											 
											<span class='uploadLine pointerCancel'>调整排序</span>
											</a-menu-item>
										<a-menu-item key="2" @click='visibledrew=true'>
											<span  class='uploadLine pointerCancel'>批量删除</span>
											</a-menu-item>
									</a-menu>
								</template>
								<a-button class='pointerCancel' style="margin-left: 20px">
									更多功能
								</a-button>
							</a-dropdown>
						</div>
					</div>
				</div>
			</div>

			<!-- <List :search_form="search_form" :where='where' :api="api" :columns="columns" ref="list" title=""
				@onSelectChange="onSelectChange" :checkable='checkable'>
				<template #count>
			

				</template>
				<template #name={record}>
					<div class="op">
						<span class="cq_color" @click="rank_Derails(record.id)">{{record.name}}</span>
					</div>
				</template>
				<template #op={record}>
					<div class="op">
						<span class="check" @click="edit(record)">编辑</span>
						<a-divider type="vertical" />
						<span class="check" @click="meropen(record)">合并</span>
						<a-divider type="vertical" />
						<a-popconfirm v-if='record.user_num==0' title="是否确认删除?" ok-text="是" cancel-text="否"
							@confirm="del_ok(record.id)" @cancel="cancel">
							<span class="del"> 删除 </span>
						</a-popconfirm>
					</div>
				</template>
			</List> -->

			<!-- 		<a-table :dataSource="datas" :columns="columns" :row-selection="visibledrew?rowSelection:null" >
		   <template #name={record}>
		   	<div class="op">
		   		<span class="cq_color" @click="rank_Derails(record.id)">{{record.name}}</span>
		   	</div>
		   </template>
		   <template #op={record}>
		   	<div class="op">
		   		<span class="check" @click="edit(record)">编辑</span>
		   		<a-divider type="vertical" />
		   		<span class="check" @click="meropen(record)">合并</span>
		   		<a-divider type="vertical" />
		   		<a-popconfirm v-if='record.user_num==0' title="是否确认删除?" ok-text="是" cancel-text="否"
		   			@confirm="del_ok(record.id)" @cancel="cancel">
		   			<span class=" check del"> 删除 </span>
		   		</a-popconfirm>
		   	</div>
		   </template>
		</a-table> -->
			<TableList  :rowKey="'id'" :where='where' :row-selection="visibledrew?rowSelection:null" :columns="columns"
				:get_table_list="get_table_list" :slot_table_list="['op','icon']" ref="list">
				<template slot="op" slot-scope="record">
					<div class='op'>
						<div class="cq_color">
							<span class="cq_color" @click='edit(record.record)'>编辑</span>
							<span style='color:#eee'> | </span>
							<span @click='meropen(record.record)'>合并</span>
							<span style='color:#eee' v-if='record.record.user_num==0'> | </span>
							<a-popconfirm title="是否确定删除该标签?" ok-text="是" cancel-text="否" @confirm="del_ok(record.record.id)"
								@cancel="cancel">
								<span class="cq_error" v-if='record.record.user_num==0'> {{record.user_num}}删除</span>
							</a-popconfirm>
						</div>
					</div>
				</template>

			</TableList>

			<a-modal v-model:visible="visible" width='40%' :title="formState.id?'编辑职务':'新增职务'" @ok="add_ok">
				<a-form :model="formState" name="basic" :label-col="{ span: 6}" :wrapper-col="{ span: 14 }"
					autocomplete="off">
					<a-form-item required label="职务名称" name="name"
						:rules="[{ required: true, message: '请填写职务名称!',trigger:'blur' }]">
						<a-input v-model:value="formState.name" />
					</a-form-item>

					<a-form-item label="职务编码" name="password">
						<a-input v-model:value="formState.code" />
					</a-form-item>
					<a-form-item label="职务说明" name="password">
						<a-textarea v-model:value="formState.explain" />
					</a-form-item>
					<a-form-item label="能力要求" name="password">
						<a-textarea v-model:value="formState.require" />
					</a-form-item>
				</a-form>
			</a-modal>

			<a-modal v-model:visible="visible2" width='40%' title="合并职务" @ok="mer_ok">
				<a-form :model="formState" name="basic" :label-col="{ span: 6}" :wrapper-col="{ span: 14 }"
					autocomplete="off">
					<a-form-item required label="当前职务" name="username"
						:rules="[{ required: true, message: 'Please input your username!' }]">
						<a-input disabled v-model:value="merinfo.name" />
					</a-form-item>
					<a-form-item  label="合并入的职务" name="password">
						<a-select v-model:value="merinfo.target_id" placeholder="请输入">
							<a-select-option :disabled='merinfo.source_id==item.id' :value="item.id" :key="item.id"
								v-for='item in datas'>{{item.name}}</a-select-option>
						</a-select>
					</a-form-item>

				</a-form>
			</a-modal>
		</div>

		<div style='height: 60px;'></div>
		<a-drawer placement="bottom" :closable="false" height="auto" :mask='false' :visible="visibledrew">
			<div style="text-align: center;">
				<a-button class='pointerCancel' @click="delClose">取消</a-button>
				<a-button class='pointerCancel' style="margin-left: 20px;" type='danger'
					@click="delSuccess">删除</a-button>
			</div>

		</a-drawer>
	</div>
</template>

<script>
	import {
		dutiesList2,
		dutiesList,
		removeDuties,
		modifyDuties,
		mergeDuties
	} from '@/api/organization.js'
	import TableList from "@/components/TableList";
	import {
		message
	} from 'ant-design-vue';
	export default {
		components: {
			TableList
		},
		data() {
			return {
				selectRowKeys: [],
				selectedRows: [],
				visibledrew: false,
				checkable: false,
				visit: true,
				visible2: false,
				search_form: false,
				username: '',
				merinfo: {
					source_id: ''
				},
				where: {},
				formState: {},
				get_table_list: dutiesList,
				columns: [{
						title: '职务名称',
						dataIndex: 'name',
						slots: {
							customRender: 'name'
						}
					},
					{
						title: '在职员工数',
						dataIndex: 'user_num',

					},
					{
						title: '全职人数',
						dataIndex: 'full_user_num',

					},
					{
						title: '职务编码',
						dataIndex: 'code',
					},
					{
						title: '职务说明',
						dataIndex: 'require',
					},
					{
						title: '操作',
						dataIndex: 'op',
						wdith: '100px',
						scopedSlots: {
							customRender: "op"
						}
					},
				],
				del_id: null, // 即将删除的规则ID
				visible: false,
				selectInfo: [],
				sort_list: [],
				datas: []
			}
		},
		created() {
			this.get_data_list()
		},
		unmounted() {},
		computed: {

			rowSelection() {
				return {
					selectedRowKeys: this.selectRowKeys,
					onChange: (selectedRowKeys, selectedRows) => {
						
						this.selectRowKeys = selectedRowKeys
						this.selectedRows = selectedRows;
					},
					getCheckboxProps: record => ({
						disabled: record.user_num != 0,
					}),
				}
			},
		},
		methods: {
			get_data_list() {
				dutiesList2({
					data: {
						is_page:-1
					}
				}).then(res => {
					this.datas = res.data.list
				})
			},
			handleChange() {
				this.$refs.list.get_list()
			},
			rank_Derails(id) {
				this.$router.push('/organization/rankDerails?id=' + id)
			},
			go_sort() {
				this.$router.push('/organization/sortRank')
			},
			meropen(item) {
				this.merinfo.name = item.name
				this.merinfo.source_id = item.id
				this.visible2 = true
				this.$refs.list.get_list()
			},
			delClose() {
				this.selectRowKeys = null
				this.visibledrew = false
				this.checkable = false
			},
			delSuccess() {
				removeDuties({
					data: {
						id: this.selectRowKeys.join(',')
					},
					info: true
				}).then(res => {
					this.selectRowKeys = null
					this.checkable = false
					this.visibledrew = false
					this.$refs.list.get_list()
				})
			},

			cancel() {
				this.formState = {}
				this.visible = false;
			},
			edit(item) {
				this.formState = JSON.parse(JSON.stringify(item))
				this.visible = true
			},
			
			onSelectChange(e) {
				this.selectInfo = e
				this.visibledrew = true
				if (this.selectInfo.length > 0) {
					this.visibledrew = true
				}
			},

			del_ok(id) {
				console.log('id',id)
				removeDuties({
					data: {
						id: id
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list()
				})
			},
			mer_ok() {
				mergeDuties({
					data: {
						...this.merinfo
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list()
					this.merinfo = {}
					this.visible2 = false;
				})
			},
			add_ok() {
				modifyDuties({
					data: {
						...this.formState
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list()
					this.formState = {}
					this.visible = false;
				})
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.table_list_body {
		margin: 0px;
		padding: 1px 16px;
	}

	.op {
		display: flex;
		align-items: center;


		.btn {
			cursor: pointer;
		}

		.check {
			color: @cq_color;
			cursor: pointer;
		}

		.del {
			color: @cq_error;
		}
	}

	.table_list {
		margin-top: 16px
	}

	.card {
		padding: 16px 24px;
		background-color: #fff;
	}

	.count_info {
		padding-bottom: 16px;
		display: flex;
	}

	/deep/.page_content {
		margin: 0;
		padding: 0;
	}
	.uploadLine{
		padding:4px 10px;
	}
</style>